import { Component } from '@angular/core';
import { TranslationHelper } from '../../../shared/helpers';
import { IconSize } from '../../../shared/types';

@Component({
  selector: 'lsb-part-box-dimensions-tooltip',
  templateUrl: './part-box-dimensions-tooltip.component.html',
  styleUrls: ['./part-box-dimensions-tooltip.component.scss'],
})
export class PartBoxDimensionsTooltipComponent {
  readonly IconSize = IconSize;
  constructor(public translations: TranslationHelper) {}
}
