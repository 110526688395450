<ng-container *ngIf="hasMachineConfigurations$ | async; else contentEmpty">
  <lsb-master-detail-layout>
    <section data-slot="sidebar">
      <xui-button
        class="new-machine-button"
        xuiClickUsageTracking="{{ uiElementIds.CONFIGURATOR_NEW_MACHINE }}"
        (click)="showChooseMachineWizard()"
      >
        {{ translations.CONFIGURATOR.CONFIGURE_NEW_MACHINE | translate }}
      </xui-button>
      <div class="button-note">
        {{ translations.CONFIGURATOR.CONFIGURATION_BUTTON_TOOLTIP | translate }}
      </div>
      <div class="button-note">
        <a
          class="output-content link"
          href="{{ translations.XGUIDE.MY_TRUMPF.MY_MACHINES_URL | translate }}"
        >
          {{ translations.XGUIDE.MY_TRUMPF.MY_MACHINES | translate }}
        </a>
      </div>
    </section>

    <section data-slot="main" data-ngtx="configurator:content" class="content">
      <ng-container *ngIf="machineConfigurations$ | async as configs">
        <lsb-configurator-list-item
          *ngFor="let machineConfiguration of configs; let index = index"
          [machineConfiguration]="machineConfiguration"
          (edit)="editConfiguration($event)"
          (remove)="removeConfiguration($event)"
          (downloadCad)="downloadCad($event)"
        ></lsb-configurator-list-item>
      </ng-container>
    </section>
  </lsb-master-detail-layout>
</ng-container>

<ng-template #contentEmpty>
  <lsb-create-configuration-view
    (createConfiguration)="createConfigurationForMachine($event)"
    (openConfigurationWizard)="createConfigurationForMachine($event)"
    class="create-view"
    data-ngtx="configurator:content-empty"
  ></lsb-create-configuration-view>
</ng-template>
