<div class="wizard-overlay">
  <div class="wizard">
    <div class="wizard-top">
      <div class="title-container">
        <h2>
          {{ translations.FIXTURE_MATE.BOX_DIMENSIONS.TITLE | translate }}
        </h2>
      </div>
      <div class="hr"></div>
    </div>

    <div class="content">
      <div class="column">
        <xui-input-field
          data-ngtx="name"
          [label]="translations.FIXTURE_MATE.BOX_DIMENSIONS.NAME | translate"
          [(text)]="nameValue.value"
        >
          <xui-value
            #nameValue
            xuiValidateRequired
            [(binding)]="box.name"
            [fallbackValue]="box.name"
            (bindingChange)="checkIsValid()"
          >
          </xui-value>
        </xui-input-field>
        <xui-input-field
          data-ngtx="length"
          [label]="translations.FIXTURE_MATE.BOX_DIMENSIONS.LENGTH | translate"
          [(text)]="length.value"
        >
          <xui-value
            #length
            xuiValidateRequired
            [xuiValidateNumber]="{ greaterThanOrEqual: 210, lessThanOrEqual: 450 }"
            [(binding)]="box.lengthX"
            [fallbackValue]="210"
            (bindingChange)="checkIsValid()"
          >
            <xui-number-formatter [maxDecimals]="0"></xui-number-formatter>
          </xui-value>

          <xui-icon
            class="info-flag total-pieces-tooltip"
            iconKey="Tooltip.Information"
            [size]="IconSize.S"
            [xuiTooltip]="lengthTooltip"
          ></xui-icon>

          <xui-tooltip #lengthTooltip>
            <p class="simple-text-tooltip">
              210 – 450 mm
            </p>
          </xui-tooltip>
        </xui-input-field>

        <xui-input-field
          data-ngtx="width"
          [label]="translations.FIXTURE_MATE.BOX_DIMENSIONS.WIDTH | translate"
          [(text)]="width.value"
        >
          <xui-value
            #width
            xuiValidateRequired
            [xuiValidateNumber]="{ greaterThanOrEqual: 150, lessThanOrEqual: 450 }"
            [(binding)]="box.widthY"
            [fallbackValue]="150"
            (bindingChange)="checkIsValid()"
          >
            <xui-number-formatter [maxDecimals]="0"></xui-number-formatter>
          </xui-value>

          <xui-icon
            class="info-flag total-pieces-tooltip"
            iconKey="Tooltip.Information"
            [size]="IconSize.S"
            [xuiTooltip]="widthTooltip"
          ></xui-icon>

          <xui-tooltip #widthTooltip>
            <p class="simple-text-tooltip">
              150 – 450 mm
            </p>
          </xui-tooltip>
        </xui-input-field>

        <xui-input-field
          data-ngtx="height"
          [label]="translations.FIXTURE_MATE.BOX_DIMENSIONS.HEIGHT | translate"
          [(text)]="height.value"
        >
          <xui-value
            #height
            xuiValidateRequired
            [xuiValidateNumber]="{ greaterThanOrEqual: 40, lessThanOrEqual: 200 }"
            [(binding)]="box.heightZ"
            [fallbackValue]="40"
            (bindingChange)="checkIsValid()"
          >
            <xui-number-formatter [maxDecimals]="0"></xui-number-formatter>
          </xui-value>

          <xui-icon
            class="info-flag total-pieces-tooltip"
            iconKey="Tooltip.Information"
            [size]="IconSize.S"
            [xuiTooltip]="heightTooltip"
          ></xui-icon>

          <xui-tooltip #heightTooltip>
            <p class="simple-text-tooltip">
              40 – 200 mm
            </p>
          </xui-tooltip>
        </xui-input-field>

        <xui-input-field
          data-ngtx="flangeWidthA"
          [label]="translations.FIXTURE_MATE.BOX_DIMENSIONS.FLANGE_WIDTH_A | translate"
          [(text)]="flangeWidthA.value"
        >
          <xui-value
            #flangeWidthA
            xuiValidateRequired
            [xuiValidateNumber]="{ greaterThanOrEqual: 0, lessThanOrEqual: 30 }"
            [(binding)]="box.flangeWidthA"
            [fallbackValue]="0"
            (bindingChange)="checkIsValid()"
          >
            <xui-number-formatter [maxDecimals]="1"></xui-number-formatter>
          </xui-value>

          <xui-icon
            class="info-flag total-pieces-tooltip"
            iconKey="Tooltip.Information"
            [size]="IconSize.S"
            [xuiTooltip]="flangeWidthATooltip"
          ></xui-icon>

          <xui-tooltip #flangeWidthATooltip>
            <p class="simple-text-tooltip">
              8,5 – 30 mm | 0 mm
            </p>
          </xui-tooltip>
        </xui-input-field>

        <xui-input-field
          data-ngtx="flangeWidthB"
          [label]="translations.FIXTURE_MATE.BOX_DIMENSIONS.FLANGE_WIDTH_B | translate"
          [(text)]="flangeWidthB.value"
        >
          <xui-value
            #flangeWidthB
            xuiValidateRequired
            [xuiValidateNumber]="{ greaterThanOrEqual: 0, lessThanOrEqual: 30 }"
            [(binding)]="box.flangeWidthB"
            [fallbackValue]="0"
            (bindingChange)="checkIsValid()"
          >
            <xui-number-formatter [maxDecimals]="1"></xui-number-formatter>
          </xui-value>

          <xui-icon
            class="info-flag total-pieces-tooltip"
            iconKey="Tooltip.Information"
            [size]="IconSize.S"
            [xuiTooltip]="flangeWidthBTooltip"
          ></xui-icon>

          <xui-tooltip #flangeWidthBTooltip>
            <p class="simple-text-tooltip">
              8,5 – 30 mm | 0 mm
            </p>
          </xui-tooltip>
        </xui-input-field>

      </div>
      <div class="column">
        <div>
          <div class="dropdown-label-section">
            <div class="dropdown-label">{{ translations.FIXTURE_MATE.BOX_DIMENSIONS.MATERIAL | translate }}</div>
          </div>
          <xui-dropdown #materialDown
                        class="drop-down"
                        [label]="selectedMaterial?.materialName"
                        data-ngtx="material-dropdown">
            <xui-dropdown-item
              *ngFor="let material of materials"
              (click)="materialSelected(material); materialDown.close()"
            >
              {{ material?.materialName }}
            </xui-dropdown-item>
          </xui-dropdown>
        </div>
        <div>
          <div class="dropdown-label-section">
            <div class="dropdown-label">{{ translations.FIXTURE_MATE.BOX_DIMENSIONS.THICKNESS | translate }}</div>
          </div>
          <xui-dropdown #thicknessDown
                        class="drop-down"
                        [label]="selectedThickness?.toString()"
                        data-ngtx="thickness-dropdown">
            <xui-dropdown-item
              *ngFor="let thickness of thicknesses"
              (click)="thicknessSelected(thickness); thicknessDown.close()"
            >
              {{ thickness }}
            </xui-dropdown-item>
          </xui-dropdown>
        </div>

        <img src="/assets/img/fixture-mate/FixtureMate_Preview_DimensionBox.jpg"   alt=''/>
      </div>

    </div>

    <div class="wizard-bottom">
      <div class="footer">
        <div class="footer-btn-container"></div>

        <div class="footer-btn-container">
          <div class="button-container">
            <!-- ATOM: Footer Button Secondary -->
            <button
              class="btn-footer-secondary"
              [xuiClickUsageTracking]="uiElementIds.FIXTURE_MATE_BOX_DIALOG_CANCEL"
              (click)="cancel()"
            >
              <span class="button-label">
                {{ translations.DIALOG.CANCEL | translate }}
              </span>
            </button>
          </div>

          <div class="button-container">
            <!-- ATOM: Footer Button Primary -->
            <button
              data-ngtx="ok-button"
              class="btn-footer-primary"
              [attr.disabled]="isValid ? null : true"
              [xuiClickUsageTracking]="uiElementIds.FIXTURE_MATE_BOX_DIALOG_OK"
              (click)="ok()"
            >
              <span class="button-label">
                {{ translations.DIALOG.OK | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
